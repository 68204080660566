import { useState } from 'react'
import styled from 'styled-components'
import FaqMenuBar from '~/components/FaqMenuBar'
import FaqQuestions from '~/components/FaqQuestions'
import { CustomHead } from '~/components/Head'
import StaticPageLayout from '~/components/layouts/StaticPageLayout'
import faqs from '~/data/faq'
import theme from '~/styles/styledComponentsTheme'
import React from 'react'
import dynamic from 'next/dynamic'
import Typography from '~/atoms/Typography'
import { GetServerSideProps, GetStaticProps } from 'next'
import CardGrid from '~/components/CardGrid'
import { CARD_SECTION_ARTICLE, CONTENT_GRID_CONTENT_TYPE_VIDEO } from '~/globals';
import publicService from '~/api/services/publicContentService'
import { AnyAaaaRecord } from 'dns'

const { getSlugContent } = publicService;
const { getMostRecentArticles } = articleService;

import VideoPlayer from '~/components/VideoPlayer'
import VideoPlayback from '~/components/VideoPlayer/VideoPlayback'
import { Flex } from '~/utils/LSTVUtils'
import { CtaColorScheme, CtaLink } from '~/components/cta/regular'
import * as S from '~/components/pages/VideoPage/index.styles';
import { ArticleCard } from '~/components/cards/ArticleCard';
import { formatDate } from '~/utils/date';
import articleService from '~/api/services/articleService';

const TopImage = styled.img`
  margin: 0 auto;
  width: 60%;
  text-align: center;
  position: relative;
  display: block;

  @media ${theme.breakpoints.isMobile} {
    width: 100%;
  }
  padding-bottom: 40px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.laptop} {
    flex-direction: row;
    margin-top: 30px;
  }
`

const Paragraph = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;

  @media ${theme.breakpoints.isMobile} {
    margin-bottom: 25px;
  }

  a {
    color: blue;
  }
`

const ParagraphHeader = styled(Paragraph)`
  text-decoration: underline;
  font-weight: bold;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.7rem;
  font-weight: bold;

  margin-bottom: 25px;

  @media ${theme.breakpoints.isMobile} {
    margin-bottom: 25px;
  }
`

export const StyledFaqQuestions = styled(FaqQuestions)`
  width: 100%;

  @media ${theme.breakpoints.laptop} {
    margin-left: 46px;
  }
`

export const Channel = styled.div`
  img {
    max-width: 100px;
  }

  padding: 5px;
`

export const ChannelLineup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ItemList = styled.ul`
  margin-left: 20px;
  list-style-type: '-   ';
  font-size: 12px;
  line-height: 1.6em;
  padding: 0 1em;

  li {
    font-size: 1.4rem;
    line-height: 1.7rem;

    a {
      color: blue;
    }
  }
`

export const Bold = styled.span`
  font-weight: bold;
  //background: #fff072;
  //border-radius: 5px;
  padding: 5px;
  font-size: 1.4rem;
  line-height: 1.6em;
`

const VideoFrame = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 20px;

  @media ${theme.breakpoints.isMobile} {
    width: 100%;
    padding: 20px 0 20px 0;
  }
`;

const Player = dynamic(() => import('react-hls-player'), { ssr: false })

const desc = 'Join co-hosts Olympic gold medalist and commentator Tara Lipinski, New York’s famed event planner José Rolón ' +
  'and renowned wedding designer Jove Meyer in examining what’s hot in today’s weddings. The series includes cinematic wedding ' +
  'films from Love Stories TV as the trio dishes on the destinations, décor and – of course – the dresses. Stream ' +
  'now for free on Chicken Soup for the Soul.';

const LSTVLivePlayerPage = (props) => {
  return (
    <>
      <CustomHead
        title='Watch Love Stories TV - Wedding Talk TV Show'
        titleOg={'Stream the Hottest Wedding Show, Wedding Talk, Free'}
        description={desc}
        // image={'https://cdn.lovestoriestv.com/ext/misc/wedding-talk.jpg'}
        image={'https://cdn.lovestoriestv.com/temp/wedding_talk_crkl_key_art_-_16x9__now_.jpg'}
        url={`/wedding-talk`}
      />
      <StaticPageLayout wideContent headerText='Wedding Talk'>
        <TopImage src="https://cdn.lovestoriestv.com/temp/wedding_talk_crkl_key_art_-_16x9__now_.jpg" />
        {/*<img src={"https://cdn.lovestoriestv.com/temp/wedding_talk_crkl_key_art_-_16x9__now_.jpg"}/>*/}
        <br/>
        <Flex direction="row" align="center" justifyContent="center">
          <CtaLink to={'https://www.chickensouptv.com/watch/bf2dfa0c-e783-4382-8100-fff469222bda/wedding-talk/sparkly-exits'} $colorScheme={CtaColorScheme.Dark} caption={'Watch Now'} />
        </Flex>
        <br/>
        <br/>
        <Paragraph>{desc}</Paragraph>
        <br />
        <br />
        <Title>Want YOUR wedding featured on Wedding Talk?</Title>
        <Paragraph>
          <Bold>Filmmakers</Bold> - Upload your weddings{' '}
          <a rel='noreferrer' target='_blank' href='/dashboard/upload-video'>
            here
          </a>{' '}
          and email <a href='mailto:videos@lovestoriestv.com'>videos@lovestoriestv.com</a> to tell us why they should be
          featured on Wedding Talk.
          <br />
          <br />
          <Bold>Couples</Bold> - Tell us about your wedding and love story{' '}
          <a
            href='https://docs.google.com/forms/d/e/1FAIpQLSfXNbbe9M8A8i4eNrrgbXMEWMlSl2eqPbC9C3FeH-_4Twc2oA/viewform?usp=sf_link'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          . The more information we have, the more likely we are to feature you!
        </Paragraph>
        <br />
        <br />

        <br/>
        <br/>
        <Title>Learn more about the weddings featured on Season 1</Title>
        {props.videos && (
          <CardGrid
            content={props.videos}
            numCards={100}
            cardType={CONTENT_GRID_CONTENT_TYPE_VIDEO}
            verbosity='card'
            options={{ showRoles: true }}
          />
        )}
        <br/>
        <br/>
        <Title>Tips For Planning Your Wedding</Title>
        <CardGrid
          content={props.articles}
          numCards={8}
          cardType={CARD_SECTION_ARTICLE}
          verbosity="card"
          options={{ showRoles: true }}
        />
        <br/>
        <br/>
        <Title>Interested In Sponsorship?</Title>
        <Paragraph>
          <a
            href='mailto:katie@lovestoriestv.com'
            target='_blank'
            rel='noreferrer'
          >
            Get in touch with us
          </a>
          {' '} to learn how your brand and products can be a part of the show.
        </Paragraph>
        <br />
        <br />
        <Title>Wedding Pros: Think you&apos;re the next wedding TV star?</Title>
        <Paragraph>
          We’re always looking for wedding pros and businesses to feature on the show. Tell us why you’d be great{' '}
          <a
            rel='noreferrer'
            target='_blank'
            href='https://docs.google.com/forms/d/e/1FAIpQLSeDcnxB5geH8j7BUHVEpz7NQ2M3md2DG8QhhHORLl0TKVCNyA/viewform'
          >
            here
          </a>
          .
        </Paragraph>
      </StaticPageLayout>
    </>
  )
}

interface Props {
  valid: true;
  videos: any;
  articles: any;
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ params }) => {
  const videos = [
    'makeda-thomas-wedding-video-september-2021',
    'shelby-sean-wedding-video-january-2022',
    'lejla-alan-wedding-video-september-2021',
    'emma-dustin-wedding-video-october-2021',
    'micah-davida-wedding-video-november-2020',
    'neha-amit-wedding-video-june-2021',
    'sunny-satnam-wedding-video-december-2021',
    'kiley-paul-wedding-video-august-2021',
    'heidi-lucas-wedding-video-june-2021',
    'jahlieh-ryan-wedding-video-april-2021',
    'kara-kevin-wedding-video-december-2019',
    'chase-allen-wedding-video-july-2021-1',
    'caitlin-andrew-wedding-video-june-2021',
    'melody-derek-wedding-video-april-2021-1',
    'eryka-moya-samuel-moya-wedding-video-april-2021',
    'ashley-alex-wedding-video-october-2020',
    'tinisha-douglas-wedding-video-october-2021',
    'maxine-matt-wedding-video-september-2021',
    'jonsi-sumarlidi-wedding-video-april-2021',
    'eva-birna-wedding-video-november-2019',
    'natalie-harley-wedding-video-september-2020',
    'bart-olivia-wedding-video-july-2019',
    'patina-sean-wedding-video-september-2021',
    'chris-jeremy-wedding-video-may-2021',
    'austin-kristine-wedding-video-august-2020',
    'ty-karli-wedding-video-august-2019',
    'tyler-shilpa-wedding-video-may-2021',
    'neil-paige-wedding-video-october-2021',
    'coco-matty-wedding-video-june-2021',
    'hanna-jerod-wedding-video-december-2021',
    'curissa-brandyn-wedding-video-april-2019-2',
    'tara-zev-wedding-video-june-2019',
    'heather-kelly-wedding-video-april-2021-1',
    'amber-john-wedding-video-september-2021-1',
    'asmeret-mickey-wedding-video-june-2021',
    'jake-hailey-wedding-video-july-2021-1',
    'morgan-becky-wedding-video-february-2022',
  ]

  let contentPromises = await Promise.allSettled(
    videos.map(
      async (item): Promise<any> => {
        try {
          return await getSlugContent({ slug: item, verbosity: 'card' })
        } catch (e) {
          return Promise.reject(e.message)
        }
      },
    )
  )

  let articleArray = await getMostRecentArticles(8);
  console.log(articleArray);

  let contentArray = contentPromises
    .map((item) => (item.status === 'fulfilled' ? item.value : undefined))
    .filter((item) => item !== undefined)

  return {
    props: { valid: true, videos: contentArray, articles: articleArray },
  }
}

export default LSTVLivePlayerPage
