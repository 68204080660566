
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/wedding-talk",
      function () {
        return require("private-next-pages/wedding-talk/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/wedding-talk"])
      });
    }
  